import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { ProgressService } from 'src/app/shared/progress';
import { AuthenticationService, UserSessionPersistenceService } from '../services';
import { endpoints } from '@environments/endpoints';
import { UserSession } from '@core/models';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    constructor(
        private userSessionPersistenceService: UserSessionPersistenceService,
        private authenticationService: AuthenticationService,
        private progressService: ProgressService,
        private router: Router,
    ) { }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.progressService.start();
        const currentUserSession = this.userSessionPersistenceService.getUserSession();
        if (currentUserSession) {
            request = this.addHeaders(request, currentUserSession);
        }

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (
                    err instanceof HttpErrorResponse && err.status === 401
                ) {
                    if (err.url != undefined && err.url!.indexOf(endpoints.auth.v1.authRefreshToken) >= 0) {
                        this.userSessionPersistenceService.removeUserSession();
                        this.progressService.complete();
                        this.router.navigateByUrl('/auth/login');
                    }
                    return this.handle401Error(request, next);
                } else {
                    return throwError(() => err);
                }
            }),
            finalize(() => this.progressService.complete())
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        let currentUserSession = this.userSessionPersistenceService.getUserSession() ?? new UserSession();
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.authenticationService
                .refreshToken(
                    currentUserSession
                )
                .pipe(
                    switchMap((userSession: UserSession) => {
                        this.isRefreshing = false;
                        currentUserSession = {
                            ...currentUserSession,
                            ...userSession
                        };

                        this.userSessionPersistenceService.setUserSession(currentUserSession);
                        this.refreshTokenSubject.next(userSession.token);
                        if (request.url.indexOf(endpoints.auth.v1.authLogout) >= 0 && request.body instanceof UserSession) {
                            return next.handle(this.addHeaders(this.handleLogout(request, userSession), currentUserSession));
                        }
                        return next.handle(this.addHeaders(request, currentUserSession));
                    })
                );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((jwt) => {
                    currentUserSession.token = jwt;
                    return next.handle(this.addHeaders(request, currentUserSession));
                })
            );
        }
    }

    private handleLogout(request: HttpRequest<any>, userSession: UserSession) {
        var logoutUserSession = request.body as UserSession;
        logoutUserSession.refreshToken = userSession.refreshToken;
        var newRequest = request.clone({
            body: logoutUserSession
        });
        return newRequest;
    }

    private addHeaders(request: HttpRequest<any>, userSession: UserSession) {
        if (request.url.indexOf(endpoints.auth.v1.authRefreshToken) >= 0) {
            return request.clone();
        }
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${userSession.token}`,
            },
        });
    }
}
