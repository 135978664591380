<div class="sidebar-header">
    <a class="branding d-flex align-items-center" href="/">
        <img [src]="logoSrc" class="branding-logo-expanded" alt="logo" />
        <span class="branding-name">{{ title }}</span>
    </a>
</div>
<div class="sidebar-main scrollbar-none mt-2">
    <mat-nav-list class="sidemenu d-block">
        <mat-list-item routerLink="/dashboard" routerLinkActive="list-item-active">
            <div class="d-flex flex-row align-items-center">
                <mat-icon class="me-1">dashboard</mat-icon>
                Dashboard
            </div>
        </mat-list-item>
        <mat-list-item routerLink="/tasks" routerLinkActive="list-item-active">
            <div class="d-flex flex-row align-items-center">
                <mat-icon class="me-1">schedule</mat-icon>
                Tasks
            </div>
        </mat-list-item>
        <mat-list-item routerLink="/http-requests" routerLinkActive="list-item-active">
            <div class="d-flex flex-row align-items-center">
                <mat-icon class="me-1">public</mat-icon>
                HTTP Requests
            </div>
        </mat-list-item>
        <mat-list-item routerLink="/facebook" routerLinkActive="list-item-active">
            <div class="d-flex flex-row align-items-center">
                <mat-icon class="me-1" svgIcon="facebook"></mat-icon>
                Facebook
            </div>
        </mat-list-item>
        <mat-expansion-panel class="no-shadow mb-2" [expanded]="getActiveClass('pinterest')">
            <mat-expansion-panel-header>
                <mat-panel-title [class.list-item-active]="getActiveClass('pinterest')">
                    <div class="d-flex flex-row align-items-center">
                        <mat-icon class="me-1" svgIcon="pinterest"></mat-icon>
                        Pinterest
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list-item routerLink="/pinterest/pins" [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="list-item-active">
                <div class="d-flex flex-row align-items-center">
                    Pins
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/pinterest/boards" [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="list-item-active">
                <div class="d-flex flex-row align-items-center">
                    Boards
                </div>
            </mat-list-item>
        </mat-expansion-panel>
        <mat-list-item routerLink="/files" routerLinkActive="list-item-active">
            <div class="d-flex flex-row align-items-center">
                <mat-icon class="me-1">folder_open</mat-icon>
                Files
            </div>
        </mat-list-item>
        <mat-expansion-panel class="no-shadow" [expanded]="getActiveClass('social-providers')">
            <mat-expansion-panel-header>
                <mat-panel-title [class.list-item-active]="getActiveClass('social-providers')">
                    <div class="d-flex flex-row align-items-center">
                        <mat-icon class="me-1">groups</mat-icon>
                        Social Providers
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list-item routerLink="/social-providers/facebook-users" [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="list-item-active">
                <div class="d-flex flex-row align-items-center">
                    Facebook
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/social-providers/pinterest-accounts"
                [routerLinkActiveOptions]="{ exact: false }" routerLinkActive="list-item-active">
                <div class="d-flex flex-row align-items-center">
                    Pinterest
                </div>
            </mat-list-item>
        </mat-expansion-panel>
        <mat-expansion-panel class="no-shadow" [expanded]="getActiveClass('config')">
            <mat-expansion-panel-header>
                <mat-panel-title [class.list-item-active]="getActiveClass('config')">
                    <div class="d-flex flex-row align-items-center">
                        <mat-icon class="me-1">settings</mat-icon>
                        Configurations
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list-item routerLink="/config/task-types" [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="list-item-active">
                <div class="d-flex flex-row align-items-center">
                    Task Types
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/config/facebook-permissions" [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="list-item-active">
                <div class="d-flex flex-row align-items-center">
                    Facebook Permissions
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/config/pinterest-scopes" [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="list-item-active">
                <div class="d-flex flex-row align-items-center">
                    Pinterest Scopes
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/config/ai-models" [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="list-item-active">
                <div class="d-flex flex-row align-items-center">
                    AI Models
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/config/ai-templates" [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="list-item-active">
                <div class="d-flex flex-row align-items-center">
                    AI Templates
                </div>
            </mat-list-item>
        </mat-expansion-panel>
    </mat-nav-list>
</div>