<mat-toolbar
    class="toolbar d-flex align-content-between flex-column flex-wrap align-items-center justify-content-center">
    <div>
        <button *ngIf="showSidenavToggle === true" mat-icon-button (click)="toggleSidenav.emit()" class="toolbar-button"
            matTooltip="Show/Hide">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div class="d-flex align-content-between flex-wrap align-content-center justify-content-center">
        <mat-slide-toggle [checked]="themeChecked" (change)="themeToggle($event)" #darkModeSwitch></mat-slide-toggle>
        <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" class="toolbar-button">
                <mat-icon>account_circle</mat-icon>
            </button>
            <mat-menu #menu="matMenu" overlapTrigger="false">
                <button mat-menu-item (click)="profile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>My Account</span>
                </button>
                <!-- <button mat-menu-item>
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
        </button> -->
                <button mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>