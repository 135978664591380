<div class="container-wrap">
    <app-progress-bar class="progress-bar-top-fixed" mode="indeterminate"></app-progress-bar>
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav #sidenav class="sidenav" [mode]="over" [opened]="opened">
            <div class="sidebar-main scrollbar-none">
                <app-sidenav></app-sidenav>
            </div>
        </mat-sidenav>
        <mat-sidenav-content>
            <app-header (toggleSidenav)="toggleSidenav(sidenav)"></app-header>
            <div class="main-content">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>