import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from '@core/services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  title: string = environment.title;
  logoSrc = './assets/images/logo.png';
  constructor(private router: Router, private themeService: ThemeService) {
    themeService.getThemeChange().subscribe(result => {
      if (result != undefined && result == 'dark-theme') {
        this.logoSrc = './assets/images/logo_dark.png';
      }
      else {
        this.logoSrc = './assets/images/logo.png';
      }
    });
  }

  getActiveClass(url: string) {
    if (this.router.url.indexOf(url) > 0) {
      return true;
    }

    return false;
  }
}
