import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards';
import { AuthLayoutComponent, ErrorLayoutComponent, MainLayoutComponent } from '@core/layout';
import { environment } from '@environments/environment';

const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', title: environment.title, redirectTo: 'dashboard', pathMatch: 'full' },
            {
                path: '',
                title: environment.title,
                loadChildren: () =>
                    import('./dashboard/dashboard.feature.module').then(
                        (m) => m.DashboardFeatureModule
                    ),
            },
            {
                path: 'config',
                title: environment.title,
                loadChildren: () =>
                    import('./configurations/configurations.feature.module').then(
                        (m) => m.ConfigurationsFeatureModule
                    ),
            },
            {
                path: 'http-requests',
                title: environment.title,
                loadChildren: () =>
                    import('./http-requests/http-requests.feature.module').then(
                        (m) => m.HttpRequestsFeatureModule
                    ),
            },
            {
                path: 'tasks',
                title: environment.title,
                loadChildren: () =>
                    import('./tasks/tasks.feature.module').then(
                        (m) => m.TasksFeatureModule
                    ),
            },
            {
                path: 'social-providers',
                title: environment.title,
                loadChildren: () =>
                    import('./social-providers/social-providers.feature.module').then(
                        (m) => m.SocialProvidersFeatureModule
                    ),
            },
            {
                path: 'facebook',
                title: environment.title,
                loadChildren: () =>
                    import('./facebook/facebook.feature.module').then(
                        (m) => m.FacebookFeatureModule
                    ),
            },
            {
                path: 'files',
                title: environment.title,
                loadChildren: () =>
                    import('./files/files.feature.module').then(
                        (m) => m.FilesFeatureModule
                    ),
            },
            {
                path: 'pinterest',
                title: environment.title,
                loadChildren: () =>
                    import('./pinterest/pinterest.feature.module').then(
                        (m) => m.PinterestFeatureModule
                    ),
            },
        ],
    },
    {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                title: environment.title,
                loadChildren: () =>
                    import('./auth/auth.feature.module').then(
                        (m) => m.AuthFeatureModule
                    ),
            },
        ],
    },
    {
        path: 'error',
        component: ErrorLayoutComponent,
        children: [
            {
                path: '',
                title: environment.title,
                loadChildren: () =>
                    import('./errors/errors.feature.module').then(
                        (m) => m.ErrorsFeatureModule
                    ),
            },
        ],
    },
    { path: '**', title: environment.title, redirectTo: 'error/not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class FeaturesRoutingModule { }