import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutModule } from "./layout";
import { AuthGuard } from "./guards";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor, ErrorInterceptor } from "./interceptors";

@NgModule({
    declarations: [],
    providers: [
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    imports: [
        CommonModule, LayoutModule,
    ],
    exports: [
        LayoutModule,
    ]
})
export class CoreModule { }