import { NgModule } from '@angular/core';
import { AuthLayoutComponent } from './auth';
import { SharedModule } from '@shared/index';
import { ErrorLayoutComponent } from './error';
import { HeaderComponent, MainLayoutComponent, SidenavComponent } from './main';
@NgModule({
    declarations: [
        AuthLayoutComponent,
        ErrorLayoutComponent,
        HeaderComponent,
        SidenavComponent,
        MainLayoutComponent,
    ],
    imports: [
        SharedModule,
    ]
})
export class LayoutModule { }