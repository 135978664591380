import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostBinding, Inject, ViewChild, OnInit } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements AfterViewInit, OnInit {
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  opened = true;
  theme = 'light';
  over: MatDrawerMode = 'side' as MatDrawerMode;

  constructor(@Inject(DOCUMENT) private document: Document, private deviceService: DeviceDetectorService,) {
  }

  ngAfterViewInit(): void {
    
  }

  ngOnInit(): void {
    this.handleNavigation();
  }

  handleNavigation() {
    if (this.deviceService.isDesktop()) {
      this.over = 'side';
    }
    else {
      this.opened = false;
      this.over = 'over';
    }
  }

  @HostBinding('class.dark-theme') get themeDark() {
    return this.theme === 'dark';
  }

  toggleSidenav(sidenav: MatSidenav) {
    sidenav.toggle();
  }
}
